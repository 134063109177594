import {Inject, Injectable} from '@angular/core'
import {BehaviorSubject, Observable, of} from 'rxjs'
import {LOCAL_STORAGE} from '../application/localstorage.provider'
import {HelperService} from '@maff/bankid'

const ACCESS_TOKEN_NAME = 'maff-onboarding-at'


/**
 * Info about the logged in state to be communicated to
 * other parts of the application
 */
export interface MaffConfiguration {
  /**
   * The access token for those who need it.
   */
  token?: string | null


  /**
   * This must always be set and should be true only if we can
   * proceed with application logic.
   */
  ready: boolean

  /**
   * The logged in person personnummer.
   */
  personNummer: string

  /**
   * Check if user has autogiro, this is fulconfig for development only
   */
  autogiro?: boolean

  /**
   *  Name from BankID
   */
  name: string
}


@Injectable({
  providedIn: 'root'
})

export class ConfigService {

  public configState$: BehaviorSubject<MaffConfiguration> =
    new BehaviorSubject<MaffConfiguration>({ready: false} as any)

  private currentConfig: MaffConfiguration = {ready: false} as any

  constructor(
    @Inject(LOCAL_STORAGE) private injectedLocalStorage: Storage
  ) {
  }

  /**
   * Checks if user is logged in.
   */
  public isLoggedIn(): Observable<boolean> {
    const accessToken = this.injectedLocalStorage.getItem(ACCESS_TOKEN_NAME) as string
    return of(this.setToken(accessToken))
  }

  /**
   * Set the authentication token
   *
   * @param token - The token as received from the login service
   */
  public setToken(token: string): boolean {
    // Always returns a payload or null
    const payload = HelperService.GetTokenPayload(token)

    if (!payload) {
      this.resetToken()
      return false
    }

    this.currentConfig = {
      token: token,
      personNummer: payload.sub,
      name: '',
      ready: true
    }
    this.configState$.next(this.currentConfig)
    this.injectedLocalStorage.setItem(ACCESS_TOKEN_NAME, token)
    return true
  }

  /**
   * Reset what ever access token we might have had
   */
  public resetToken(): boolean {
    this.injectedLocalStorage.removeItem(ACCESS_TOKEN_NAME)
    this.currentConfig = {ready: false} as any
    this.configState$.next(this.currentConfig)
    return false
  }

  public getAccessToken(): string | undefined | null {
    return this.currentConfig.token
  }

  public userName(): string {
    return this.currentConfig.name
  }

  public isAdmin(): boolean {
    const admins = [
      '197309193592', // Daniel
      '198102163980', // Helena
      '197809224038' // Sami
    ]
    return admins.indexOf(this.currentConfig.personNummer) !== -1
  }
}
