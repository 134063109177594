import {APP_INITIALIZER, LOCALE_ID, NgModule} from '@angular/core'
import {BrowserModule} from '@angular/platform-browser'

import {AppRoutingModule} from './app-routing.module'
import {AppComponent} from './app.component'
import {WINDOW_PROVIDERS} from './application/window.provider'
import {LOCAL_STORAGE_PROVIDERS} from './application/localstorage.provider'
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http'
import {AuthInterceptor} from './application/auth-interceptor'
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'
import {ResponseInterceptor} from './application/response-interceptor'

import '@angular/common/locales/global/fr'
import {ConfigService} from './services/config.service'
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field'

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule
  ],
  providers: [
    WINDOW_PROVIDERS,
    LOCAL_STORAGE_PROVIDERS,
    {provide: LOCALE_ID, useValue: 'fr'},
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true},
    {
      // This is nice magic to make sure we load any existing tokens.
      // We return an observable, the "angulars" will subscribe to this
      // and when it completes, it will continue.
      provide: APP_INITIALIZER,
      useFactory: (configService: ConfigService) => () => configService.isLoggedIn(),
      deps: [ConfigService],
      multi: true
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {floatLabel: 'always', hideRequiredMarker: true, appearance: 'outline'}
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
